<script setup lang="ts">
import {toTypedSchema} from '@vee-validate/zod';
import * as z from 'zod';
import {useField} from 'vee-validate';
import type {HTMLAttributes} from 'vue'
import {cn} from '@/lib/utils'

const userProfile = useUserProfileStore()
const {t} = useI18n()
const props = defineProps({
    showVerifyLabel: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
    },
    class: {
        type: String as PropType<HTMLAttributes['class']>
    },
    input_class: {
        type: String as PropType<HTMLAttributes['class']>
    },
    required: {
        type: Boolean,
        default: true
    }
})

const usedPlaceholder = computed(() => {
    return props.placeholder || t('Enter your email address')
})

const validateSchema = ref()
validateSchema.value =
    z.string({message: 'Email required'})
    .min(6, {message: "Email must be at least 6 characters long"})
    .max(50, {message: "Email must be no longer than 50 characters"})
    .email({message: "Invalid email format"})
    .regex(/^\S*$/, "Email must not contain spaces")

if(!props.required){
    validateSchema.value = validateSchema.value.optional()
}

const {value: emailAddress, errorMessage, setValue} = useField('email', toTypedSchema(validateSchema.value));

const handleInputEmail = (event: Event) => {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.replace(/\s/g, '').slice(0, 38);
    setValue(inputElement.value, true)
};
</script>

<template>
    <UiFormField v-slot="{ componentField }" name="email">
        <UiFormItem :class="cn('relative', props.class)">
            <UiFormLabel>
                <div class="flex justify-between items-center mb-1">
                    <p>{{ $t("Email Address") }} <span v-if="required" class="text-primaryblue-500">*</span></p>
                    <template v-if="showVerifyLabel">
                        <p v-if="!userProfile.Email" class="ltr:ml-4 rtl:mr-3 ys-text-verify flex">{{ $t("Verify") }}
                            <Icon size="16" name="ri:arrow-right-up-line"/>
                        </p>
                        <p v-if="userProfile.Email" class="ltr:ml-4 rtl:mr-3 ys-text-verified flex">{{ $t("Verified") }}
                            <Icon size="16" name="ri:check-line"/>
                        </p>
                    </template>
                </div>
            </UiFormLabel>
            <UiFormControl>
                <UiInput @input="handleInputEmail" :disabled="disabled" icon="ri:mail-line"
                         :class="cn('ys-form-input ltr:pl-9 rtl:pr-9', props.input_class)" type="email"
                         :placeholder="usedPlaceholder" v-bind="componentField"/>
            </UiFormControl>
            <UiFormMessage/>
        </UiFormItem>
    </UiFormField>
</template>

<style scoped lang="scss">
.ys-text-verified {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: right;
    color: rgba(45, 159, 117, 1);
}

.ys-text-verify {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: right;
    color: rgba(242, 174, 64, 1);
}
</style>