<script setup lang="ts">
import type {HTMLAttributes} from "vue";
import * as z from "zod";
import {useField} from "vee-validate";
import {toTypedSchema} from "@vee-validate/zod";
import {cn} from "~/lib/utils";

const dictionaryStore = useDictionaryStore()
const model = defineModel<any>();
const {t} = useI18n()
const props = defineProps({
    disabled: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
    },
    class: {
        type: String as PropType<HTMLAttributes['class']>
    },
    input_class: {
        type: String as PropType<HTMLAttributes['class']>
    },
    required: {
        type: Boolean,
        default: true
    },
    name: {
        type: String,
        default: 'location_id'
    },
    default: {
        type: Number,
    }
})

const usedPlaceholder = computed(() => {
    return props.placeholder || t('Select location')
})

const selectedItem = computed(() => {
    if(!model.value) return null
    return dictionaryStore.Cities.find(item => item.id === model.value)
})

const validateSchema = ref()
validateSchema.value =
    z.number({message: 'Location is required'})

if(!props.required){
    validateSchema.value = validateSchema.value.optional()
}

const {setValue} = useField(props.name, toTypedSchema(validateSchema.value));
watch(model, (val) => {
    setValue(val, true)
})

onMounted(() => {
    if(props.default){
        model.value = props.default
    }
})
</script>

<template>
    <UiFormField v-model="model" v-slot="{ componentField, meta }" :name="name">
        <UiFormItem :class="cn('relative', props.class)">
            <UiFormControl>
                <UiSelect v-bind="componentField" >
                    <UiSelectTrigger
                        :class="cn('w-full !ring-0 !ring-offset-0 rtl:flex-row-reverse justify-between font-normal text-sub text-sm h-[46px] px-3 rounded-xl', props.input_class)"
                    >
                        <div class="flex items-center rtl:flex-row-reverse">
                            <img
                                v-if="selectedItem"
                                :src="selectedItem.photo"
                                class="rounded-full w-5 h-5 object-cover ltr:mr-2 rtl:ml-2">
                            <UiSelectValue
                                :placeholder="usedPlaceholder"
                                :class="{
                                    'text-neutral-400': !model,
                                    'text-black': !!model,
                                }"/>
                        </div>
                    </UiSelectTrigger>
                    <UiSelectContent>
                        <UiSelectItem v-for="city in dictionaryStore.Cities"
                                      :value="city.id" class="hover:bg-stroke">
                            <span>{{ city.name }}</span>
                        </UiSelectItem>
                    </UiSelectContent>
                </UiSelect>
            </UiFormControl>
            <UiFormMessage/>
        </UiFormItem>
    </UiFormField>
</template>