<script lang="ts" setup>
import type {FormContext} from 'vee-validate'
import iconFb from '~/assets/img/fb.svg'
import iconX from '~/assets/img/x.svg'
import iconIn from '~/assets/img/in.svg'
import iconLi from '~/assets/img/li.svg'
import iconArrow from '~/assets/img/icon_arrow.svg'
import Fade from 'embla-carousel-fade'
import Autoplay from 'embla-carousel-autoplay'
import type {EmblaCarouselType} from "embla-carousel";
import {promoRegister} from "~/services/master-service";
import {handleValidationErrors} from "~/lib/validation";
import {useWindowSize} from '@vueuse/core'

const Loader = useIsLoadingStore()
const dictionaryStore = useDictionaryStore()
const statesStore = useStatesStore()
const router = useRouter()
const {width, height} = useWindowSize({initialHeight: 0})
const config = useRuntimeConfig()

definePageMeta({
    layout: false,
});

useHead(
    {
        title: 'Sign up for YourService',
        meta: [
            {
                name: 'description',
                content: 'Your Service is open to specialists in any field — from programmers and teachers to gardeners and stylists. Our platform is designed to provide an opportunity to find work in the field that interests you.'
            },
            {
                property: 'og:title',
                content: 'Sign up for YourService'
            },
            {
                property: 'og:description',
                content: 'Your Service is open to specialists in any field — from programmers and teachers to gardeners and stylists. Our platform is designed to provide an opportunity to find work in the field that interests you.'
            }
        ]
    }
)

const slides = [
    {
        src: '/img/promo_img_1.jpg',
        title: 'Be your own boss',
        title_sm: 'Be your own boss',
        description: 'Flexible schedule and freedom to choose your income. Make freelancing a lifestyle where work isn’t just a routine but a chance to develop your potential, while maintaining a healthy balance between career and personal life.'
    }, {
        src: '/img/promo_img_2.jpg',
        title: 'Intuitive interface',
        title_sm: 'Intuitive interface',
        description: 'Forget complex layouts and extra clicks. Everything you need is just a tap away, making it easier and clearer to find the right specialist or job.'
    }, {
        src: '/img/promo_img_3.jpg',
        title: 'Secure Payments, Peace of Mind',
        title_sm: 'Secure Payments, Peace of Mind',
        description: 'Your safety is our priority. Pay for services with confidence, knowing your transactions are fully protected at every step.'
    },
]
const currentSlide = ref(0)
const sliderApi = ref<EmblaCarouselType>()
const sliderHeight = ref<number | null>(null);
const userExists = ref(false);

interface PromoUserForm {
    name: string | null,
    email: string | null,
    phone: string | null,
    location: number | null,
    specifications: number[]
}

const form = ref<PromoUserForm>({
    name: null,
    email: null,
    phone: null,
    location: null,
    specifications: [],
})

const socials = ref([
    {
        link: 'https://www.facebook.com/yourserviceae',
        img: iconFb,
    },
    {
        link: 'https://x.com/yourserviceae',
        img: iconX,
    },
    {
        link: 'https://www.linkedin.com/company/yourserviceae/',
        img: iconLi,
    },
    {
        link: 'https://www.instagram.com/yourservice.ae',
        img: iconIn,
    },
])

const defaultLocationId = computed(() => {
    return dictionaryStore.Cities?.find(item => item.name === 'Dubai')?.id || undefined
})

const defaultDialCode = computed(() => {
    return dictionaryStore.CodePhoneList?.find(item => item.name === 'UAE') || undefined
})

async function onSubmit(payload: PromoUserForm, context: FormContext) {
    if(payload.phone_number){
        payload.phone_number = statesStore.CurrentDialCode?.dial_code + payload.phone_number
    }

    await promoRegister(payload)
        .then((res) => {
            router.replace('/promo/thankyou')
        })
        .catch((error) => {
            if(error?.data?.error === 'user_exists'){
                userExists.value = true
            }
            handleValidationErrors(error, context.setErrors);
        })
}

onMounted(() => {
    sliderApi.value?.on('slidesInView', (val) => currentSlide.value = val.slidesInView()[0])
    sliderHeight.value = height.value - 40
})

</script>

<template>
    <div class="bg-stroke min-w-screen min-h-screen">
        <div class="container p-5 md:p-10">
            <div class="grid grid-cols-12 gap-5 min-h-[calc(100vh_-_5rem)]">
                <img alt="Yourservice" class="col-span-12 mx-auto max-h-[34px] block md:hidden"
                     src="/img/ys_footer_logo.svg">
                <div class="col-span-12 lg:col-span-6 xl:col-span-5 flex flex-col order-2 lg:order-1">
                    <div class="bg-white rounded-2xl p-5 md:p-10">
                        <img alt="Yourservice" class="max-h-[34px] hidden md:block mb-6" src="/img/ys_footer_logo.svg">
                        <div class="">
                            <h1 class="font-medium text-2xl">
                                {{ $t('The platform that connects clients and specialists.') }}</h1>
                            <p class="mt-2 text-sm sm:text-base text-gray-500">{{
                                    $t('Your Service is&nbsp;open to&nbsp;specialists in&nbsp;any field&nbsp;&mdash; from programmers and teachers to&nbsp;gardeners and stylists. Our platform is&nbsp;designed to&nbsp;provide an&nbsp;opportunity to&nbsp;find work in&nbsp;the field that interests you.')
                                }}</p>
                        </div>
                        <UiForm v-slot="{ errors, meta }" class="mt-6 grid grid-cols-2 gap-4" @submit="onSubmit">
                            <CustomUiInputFullName v-model="form.name" :placeholder="$t('Your name')" class="col-span-2"
                                                   input_class="h-[46px]" name="name"/>

                            <CustomUiInputEmail v-model="form.email" :placeholder="$t('Your Email')"
                                                class="col-span-2 sm:col-span-1" input_class="h-[46px]"/>

                            <CustomUiInputPhone v-model="form.phone" :defaultDialCode="defaultDialCode"
                                                :required="false" class="col-span-2 sm:col-span-1"
                                                input_class="h-[46px]"/>

                            <CustomUiSelectLocation v-model="form.location" :default="defaultLocationId"
                                                    class="col-span-2"
                                                    input_class="h-[46px]" name="location"/>

                            <CustomUiSelectSpecifications
                                v-model="form.specifications"
                                class="col-span-2"
                            />

                            <div v-if="errors?.general" class="col-span-2">
                                <UiAlert class="bg-red-50 border-none" variant="destructive">
                                    <Icon class="text-danger" name="ri:error-warning-line" size="16px"/>
                                    <UiAlertTitle>{{ $t('Error') }}</UiAlertTitle>
                                    <UiAlertDescription>{{ errors.general }}</UiAlertDescription>
                                </UiAlert>
                            </div>

                            <UiButton
                                :disabled="!errors?.general && !meta.valid || Loader.isLoading"
                                class="col-span-2 uppercase mt-4 rounded-xl"
                                size="lg"
                                type="submit"
                                variant="primary">
                                <Icon v-if="Loader.isLoading" name="svg-spinners:ring-resize"/>
                                <span v-else>{{ $t("Submit") }}</span>
                            </UiButton>
                        </UiForm>
                        <p class="mt-2 text-sm text-gray-500 text-center col-span-2">
                            {{ $t('By submitting, you agree to our') }}
                            <NuxtLink class="text-black hover:text-gray-700 transition-all" to="/page/1">
                                {{ $t('Privacy Policy') }}
                            </NuxtLink>
                        </p>
                    </div>
                    <div class="mt-auto">
                        <div class="flex flex-col sm:flex-row items-center sm:items-end justify-between gap-6 mt-6">
                            <div class="flex gap-6">
                                <a v-for="item in socials" :href="item.link" target="_blank">
                                    <Component :is="item.img" class="hover:opacity-70 transition-all"/>
                                </a>
                            </div>
                            <div v-if="config.public.playstoreLink || config.public.appstoreLink"
                                 class="flex items-center gap-6">
                                <a :href="config.public.playstoreLink" target="_blank">
                                    <NuxtImg class="ys-app-emblem h-[35px]" src="/home_page/googleplay.svg"/>
                                </a>
                                <a :href="config.public.appstoreLink" target="_blank">
                                    <NuxtImg class="ys-app-emblem h-[35px]" src="/home_page/appstore.svg"/>
                                </a>
                            </div>
                        </div>
                        <p class="mt-4 text-sm text-gray-500 col-span-2 text-center md:ltr:text-left md:rtl:text-right">
                            {{ $t('© YOURSERVICE FZCO') }} {{ new Date().getFullYear() }}
<!--                            <NuxtLink class="text-black hover:text-gray-700 transition-all" to="/page/2">
                                {{ $t('Terms of Service') }}
                            </NuxtLink>-->
                        </p>
                        <div v-if="dictionaryStore.rulePages.length" class="flex flex-wrap gap-5 mt-4">
                                <NuxtLink v-for="page in dictionaryStore.rulePages" class="text-center md:ltr:text-left md:rtl:text-right text-sm my-auto w-full md:w-auto hover:text-gray-400 cursor-pointer" :to="`/promo/${page.id}`">
                                    {{ page.title}}
                                </NuxtLink>
                        </div>
                    </div>
                </div>

                <div class="col-span-12 lg:col-span-6 xl:col-span-7 order-1 lg:order-2">
                    <UiCarousel
                        v-slot="{ scrollNext, scrollPrev }"
                        :opts="{loop: true, duration: 25}"
                        :plugins="[
                          Fade(),
                          Autoplay({
                            delay: 5000,
                            stopOnMouseEnter: true,
                            stopOnInteraction: false,
                        })]"
                        :style="sliderHeight ? `max-height: ${sliderHeight}px` : null"
                        class="h-full flex"
                        @init-api="(val) => sliderApi = val">
                        <UiCarouselContent class="h-full">
                            <UiCarouselItem v-for="item in slides" class="h-full">
                                <div
                                    :style="`background-image: url(${item.src})`"
                                    class="bg-cover bg-no-repeat bg-center rounded-2xl h-full p-4 md:p-6 flex flex-col min-h-[450px]">
                                    <div class="mt-auto">
                                        <div class="rounded-2xl bg-primary-500 p-4 md:p-6">
                                            <div class="flex items-center justify-between gap-6">
                                                <div class="flex">
                                                    <div
                                                        class="rounded-full h-[32px] w-[32px] md:h-[60px] md:w-[60px] border border-white shrink-0"></div>
                                                    <div
                                                        class="rounded-full h-[32px] md:h-[60px] border border-white flex items-center text-center text-white text-sm md:text-base xl:text-[18px] -ml-3 md:-ml-6 px-4 md:px-9">
                                                        <span class="md:hidden">
                                                            {{ item.title_sm }}
                                                        </span>
                                                        <span class="hidden md:inline">
                                                            {{ item.title }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <nav class="gap-3 hidden xl:flex">
                                                    <UiButton
                                                        class="rounded-full bg-white bg-opacity-10 lg:w-[60px] lg:h-[60px] xl:w-[75px] xl:h-[75px] hover:bg-white hover:bg-opacity-20"
                                                        @click.prevent="scrollPrev">
                                                        <iconArrow class="rotate-180 max-h-full max-w-full"/>
                                                    </UiButton>
                                                    <UiButton
                                                        class="rounded-full bg-white bg-opacity-10 lg:w-[60px] lg:h-[60px] xl:w-[75px] xl:h-[75px] hover:bg-white hover:bg-opacity-20"
                                                        @click.prevent="scrollNext">
                                                        <iconArrow class="max-h-full max-w-full"/>
                                                    </UiButton>
                                                </nav>
                                            </div>
                                            <p class="mt-4 md:mt-6 text-white text-xs md:text-sm">
                                                {{ item.description }}
                                            </p>
                                        </div>
                                        <div v-if="slides.length > 1"
                                             class="slider_dots mt-2 flex justify-center gap-1 md:hidden">
                                            <div
                                                v-for="(item, i) in slides"
                                                :class="{
                                                    'bg-opacity-100 bg-primary-500': i === currentSlide,
                                                    'bg-opacity-50 bg-white ': i != currentSlide,
                                                }"
                                                class="slider_dots__item rounded-full w-2 h-2 transition-all">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </UiCarouselItem>
                        </UiCarouselContent>
                    </UiCarousel>
                </div>
            </div>
        </div>

        <UiAlertDialog v-model:open="userExists">
            <UiAlertDialogContent class="gap-2">
                <Icon name="ri:error-warning-line" size="60px" class="text-red-700 mx-auto" />
                <p class="text-xl text-center font-bold">{{$t('This user already exist')}}</p>
                <p class="text-center">{{$t('Please choose different email or phone number.')}}</p>
                <UiAlertDialogFooter class="!justify-center mt-4">
                    <UiAlertDialogCancel class="px-8">{{$t('OK')}}</UiAlertDialogCancel>
                </UiAlertDialogFooter>
            </UiAlertDialogContent>
        </UiAlertDialog>

    </div>
</template>


<style></style>